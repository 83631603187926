<template>
  <div>
    <section id="container">
      <section id="content">
        <section class="studybox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>Study Room</h2>
          </div>
          <section class="study_myinfo_list_area">
            <div class="mypage_scroll_content">
              <section class="study_main_box">
                <div style="width:100%;margin-bottom:30px;">
                  <CalendarMonth :legends="legends" :events="events" @updateLegend="updateLegend" :demo='demo'></CalendarMonth>
                  <StudyRoomMainWeeklyArea :classes="classes" :demo='demo'></StudyRoomMainWeeklyArea>
                  <div class="clearfix"></div>
                </div>
                <StudyRoomMainLearnArea :latestLearningResults="latestLearningResults" :demo='demo'></StudyRoomMainLearnArea>
              </section>
            </div>
          </section>
        </section>
      </section>
    </section>
  </div>
</template>

<script>

import CalendarMonth from '@/components/Calendar/CalendarMonth'
import StudyRoomMainWeeklyArea from '@/components/StudyRoom/StudyRoomMainWeeklyArea'
import StudyRoomMainLearnArea from '@/components/StudyRoom/StudyRoomMainLearnArea'
import User from '@/models/User'

export default {
  name: 'Demo.StudyRoom',
  components: {
    CalendarMonth,
    StudyRoomMainWeeklyArea,
    StudyRoomMainLearnArea
  },
  data () {
    return {
      loading: true,
      enrollments: [],
      events: [],
      classes: [],
      latestLearningResults: [],
      legends: [
        'Scheduled',
        'Attend',
        'Absent',
        'Teacher Cancel',
        'Postponed'
      ],
      demo: true
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.loading = true
      const response = await User.demoGetSchedule({ legends: this.legends, demo: this.demo })
      this.enrollments = response.data.enrollments
      this.events = response.data.events
      this.classes = response.data.classes
      this.latestLearningResults = response.data.latestLearningResults
      this.loading = false
    },
    async updateLegend (data) {
      var index = this.legends.indexOf(data)
      if (index !== -1) {
        this.legends.splice(index, 1)
      } else {
        this.legends.push(data)
      }
      this.loadData()
    }
  }
}

</script>

<style scoped>

</style>
